const baseUrl = import.meta.env.VITE_IMAGE_RESIZE_DOMAIN;

function imageMutator(
  resizingType = "fill",
  width: number | string,
  height: number | string,
  gravity = "ce",
  enlarge = 0,
  paramUrl: string,
  format: string
) {
  const url = `${baseUrl}/pr:sharp/rs:${resizingType}:${width}:${height}:${enlarge}/g:${gravity}`;
  try {
    // don't use mutator if in histoire story
    if (import.meta.env.VITE_DISABLE_IMAGE_RESIZE_SERVICE) {
      return url;
    }
    const encodedUrl = btoa(paramUrl);
    const parsedUrl = `${url}/${encodedUrl}`;
    if (format) {
      return `${parsedUrl}.${format}`;
    }
    return parsedUrl;
  } catch (err) {
    // if encode process failed, use plain url (usually file name contain Thai charcter)
    const parsedUrl = `${url}/plain/${paramUrl}`;
    if (format) {
      return `${parsedUrl}@${format}`;
    }
    return parsedUrl;
  }
}

export default imageMutator;
